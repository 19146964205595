import React from 'react'
import camelcase from 'camelcase'
import {
  assocPath, evolve, pick, o, __
} from 'ramda'
import StoryblokComponent from 'components/StoryblokComponent'
import ThemeProvider from 'components/ThemeProvider'
import { NavProvider } from 'components/NavProvider'
import Navigation from 'components/Navigation/editable'
import Modal from 'components/Modal/editable'
import Footer from 'components/Footer/editable'
import Layout from 'components/Layout'
import GlobalStyle from 'utils/globalStyle'
import CoverFilter from 'components/StoryblokTileProductCoverFilter'
import HighlightPinpoint from 'components/StoryblokTileProductHighlightPinpoint'
import HighlightCarousel from 'components/StoryblokTileProductHighlightCarousel'
import Title from 'components/StoryblokTileProductTitle'
import Helmet from 'react-helmet'
import { processRemark } from 'hooks/useRemark'
import { baseColors } from 'utils/theme'

import { setConfig } from 'react-hot-loader'
import storyblokConfig from '../../storyblok-config'

setConfig({
  // set this flag to support SFC if patch is not landed
  pureSFC: true
})

const loadStoryblokBridge = () =>
  new Promise((resolve) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${
      'kl99ETlGOLJhYxIcsGu9Mwtt'
    }`
    script.onload = resolve
    document.getElementsByTagName('head')[0].appendChild(script)
  })

const getParam = function (val) {
  let result = ''
  let tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

class StoryblokEntry extends React.Component {
  constructor (props) {
    super(props)
    this.state = { story: null }
  }

  componentDidMount () {
    loadStoryblokBridge().then(() => this.initStoryblokEvents())
  }

  loadStory (
    payload,
    key = 'story',
    callback = data => Promise.resolve(data.story)
  ) {
    window.storyblok.get(
      {
        slug: payload.storyId,
        version: 'draft'
      },
      (data) => {
        callback(data).then(story => this.setState({ [key]: story }))
      }
    )
  }

  storiesByUuids (by_uuids, starts_with) {
    return new Promise(resolve =>
      window.storyblok.getAll(
        { by_uuids, version: 'draft', starts_with },
        resolve
      )
    )
  }

  initStoryblokEvents () {
    this.loadStory(
      {
        storyId: getParam('path')
      },
      'originalStory',
      (data) => {
        const lang =
          data.story?.lang === 'default' ? '' : `${data.story?.lang}/`
        if (data.story?.content?.component === 'page') {
          this.loadStory({ storyId: `/${lang}navigation` }, 'navigation')
          this.loadStory({ storyId: `/${lang}footer` }, 'footer')
        }
        if (data.story?.content?.component === 'product') {
          return Promise.all([
            this.storiesByUuids(
              data.story.content.highlights?.map(hl => hl.highlight).join(','),
              lang
            ).then(({ stories }) =>
              stories.map(story => ({
                ...story,
                component: {
                  ...story.content,
                  highlight: story.content.highlight
                }
              }))
            ),
            this.storiesByUuids(
              data.story.content.properties?.join(','),
              lang
            ).then(({ stories }) =>
              this.storiesByUuids(
                stories
                  .reduce((acc, story) => {
                    const newAcc =
                      acc.indexOf(story.content.group) > -1
                        ? acc
                        : [...acc, story.content.group]
                    story.content.properties.forEach((prop) => {
                      if (acc.indexOf(prop) === -1) newAcc.push(prop)
                    })
                    return newAcc
                  }, [])
                  .join(','),
                lang
              ).then(({ stories: subStories }) =>
                stories.map(story => ({
                  ...story,
                  component: {
                    ...story.content,
                    group: subStories.find(
                      ({ uuid }) => story.content.group === uuid
                    ),
                    properties: story.content.properties.map(prop =>
                      subStories.find(({ uuid }) => prop === uuid)
                    )
                  }
                }))
              )
            ),
            this.storiesByUuids(
              data.story.content.accessories.join(','),
              lang
            ).then(({ stories }) =>
              this.storiesByUuids(
                stories
                  .reduce((acc, story) => {
                    const newAcc =
                      acc.indexOf(story.content.group) > -1
                        ? acc
                        : [...acc, story.content.group]
                    story.content.accessories.forEach((prop) => {
                      if (acc.indexOf(prop) === -1) newAcc.push(prop)
                    })
                    return newAcc
                  }, [])
                  .join(','),
                lang
              ).then(({ stories: subStories }) =>
                stories.map(story => ({
                  ...story,
                  component: {
                    ...story.content,
                    group: subStories.find(
                      ({ uuid }) => story.content.group === uuid
                    ),
                    accessories: story.content.accessories.map(prop =>
                      evolve({ content: { description: processRemark } })(
                        subStories.find(({ uuid }) => prop === uuid)
                      )
                    )
                  }
                }))
              )
            )
          ]).then(([highlights, properties, accessories]) => ({
            ...data.story,
            full_slug: undefined,
            component: {
              ...data.story.content,
              accessories: data.story.content.accessories.map(id =>
                accessories.find(({ uuid }) => uuid === id)
              ),
              properties: data.story.content.properties.map(id =>
                properties.find(({ uuid }) => uuid === id)
              ),
              highlights: data.story.content.highlights.map(hl => ({
                ...hl,
                highlight: [
                  highlights.find(({ uuid }) => uuid === hl.highlight)
                ]
              }))
            }
          }))
        }
        return Promise.resolve(data.story)
      }
    )

    const sb = window.storyblok

    /* sb.on(['change', 'published'], (payload) => {
      this.loadStory(payload)
    }) */

    sb.on('input', (payload) => {
      // if (this.state.story && payload.story.id === this.state.story.id) {
      console.log(payload)
      payload.story.content = sb.addComments(
        payload.story.content,
        payload.story.id
      )
      this.setState(assocPath(['story', 'content'], payload.story.content))
      // }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render () {
    const { story, originalStory } = this.state

    if (!story && !originalStory) {
      return <div />
    }
    const { content, lang } = story || originalStory
    console.log(content)
    return (
      <NavProvider lang={lang}>
        <ThemeProvider colorTheme={content.colorTheme || 'green'}>
          <>
            <Helmet>
              <meta httpEquiv="Content-Security-Policy" content="frame-ancestors https://app.storyblok.com/" />
            </Helmet>
            <GlobalStyle />
            {content.component === 'landingpage' && (<Layout><Components component={content} /></Layout>)}
            {content.component === 'page' && (
              <Layout>
                <Navigation
                  data={this.state.navigation?.content || { items: [] }}
                />
                <Components component={content} />
                <Footer data={this.state.footer?.content || { items: [] }} />
              </Layout>
            )}
            {content.component === 'footer' && (
              <Layout>
                <Footer data={content} />
              </Layout>
            )}
            {content.component === 'navigation' && (
              <Layout>
                <Navigation data={content} />
                <div
                  style={{ flex: 1, backgroundColor: 'grey', width: '100%' }}
                />
              </Layout>
            )}
            {content.component === 'product' && (
              <Layout>
                <div
                  style={{
                    width: '530px',
                    height: '170px',
                    position: 'relative'
                  }}
                >
                  <Title
                    data={{
                      product: [
                        {
                          component: evolve({ description1: processRemark })({
                            ...content,
                            ...pick(
                              ['accessories', 'highlights', 'properties'],
                              originalStory?.component
                            )
                          })
                        }
                      ]
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '530px',
                    height: '350px',
                    position: 'relative'
                  }}
                >
                  <HighlightPinpoint
                    data={{
                      product: [
                        {
                          component: evolve({
                            description1: o(
                              assocPath(
                                ['childMarkdownRemark', 'htmlAst'],
                                __,
                                {}
                              ),
                              processRemark
                            )
                          })({
                            ...content,
                            ...pick(
                              ['accessories', 'highlights', 'properties'],
                              originalStory?.component
                            )
                          })
                        }
                      ]
                    }}
                    dimensions={() => ({ ratio: 530 / 350 })}
                  />
                </div>
                <div
                  style={{
                    width: '530px',
                    height: '350px',
                    position: 'relative'
                  }}
                >
                  <HighlightCarousel
                    data={{
                      product: [
                        {
                          component: evolve({ description1: processRemark })({
                            ...content,
                            ...pick(
                              ['accessories', 'highlights', 'properties'],
                              originalStory?.component
                            )
                          })
                        }
                      ]
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '1070px',
                    height: '290px',
                    position: 'relative'
                  }}
                >
                  <CoverFilter
                    data={{
                      product: [
                        {
                          component: evolve({ description1: processRemark })({
                            ...content,
                            ...pick(
                              ['accessories', 'highlights', 'properties'],
                              originalStory?.component
                            )
                          })
                        }
                      ]
                    }}
                    dimensions={() => ({ ratio: 1070 / 290 })}
                  />
                </div>
              </Layout>
            )}
            {content.component === 'modal' && (
              <Modal data={{ component: content }} />
            )}
          </>
        </ThemeProvider>
      </NavProvider>
    )
  }
}

const Components = ({ component }) =>
  component.body.map((el, idx) => (
    <StoryblokComponent
      key={el._uid}
      component={el}
      previous={`S${camelcase(
        `toryblok-${component.body[idx - 1]?.component}`
      )}`}
      next={`S${camelcase(`toryblok-${component.body[idx + 1]?.component}`)}`}
    />
  ))

export default StoryblokEntry
